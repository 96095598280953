<template>
  <div class="banner_wrap">
    <div class="banner_box">
      <div class="swiper">
        <el-carousel height="480px" :autoplay="true" :interval="6000" ref="carouse" @change="change">
          <el-carousel-item v-for="item in swiperList" :key="item.id">
            <img class="swiper_img" :src="item.image.url" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="dot-title">
          <div v-for="(item, index) in swiperList" @mouseenter="changeSlide(index)" :key="index" :class="['title-item', title_index == index ? 'active-title' : '']">{{ item.title }}</div>
        </div>
      </div>
      <div class="banner_container">
        <div class="content">
          <goodsCate />
          <div class="flex_1 flex_column">
            <div @click="click" class="flex_1"></div>
            <div class="flex_row" style="height: 118px">
              <div class="img_box" v-for="item in threeList" :key="item.id" @click="clickarr(item)">
                <img :src="item.image.url" alt="" />
              </div>
            </div>
          </div>
          <info />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import goodsCate from "./goodsCate.vue";
import info from "./info.vue";
import { handleJump } from "@/utils";
export default {
  components: {
    goodsCate,
    info,
  },
  data() {
    return {
      swiperList: [],
      threeList: [],
      handleJump,
      index: 0,
      title_index: 0,
    };
  },
  created() {
    this.$api("home.getNavInfo").then((res) => {
      this.swiperList = res.data.banner;
      this.threeList = res.data.home_adv2;
    });
  },
  methods: {
    click() {
      this.$api("home.getClick", { adv_id: this.swiperList[this.index].id });
      let jump = this.swiperList[this.index].jump;
      handleJump(jump);
    },
    clickarr(e) {
      this.$api("home.getClick", { adv_id: e.id });
      handleJump(e.jump);
    },
    change(e) {
      this.index = e;
      this.title_index = e;
    },
    changeSlide(index) {
      this.index = index;
      this.title_index = index;
      this.$refs.carouse.setActiveItem(index);
    },
  },
};
</script>

<style lang="less" scoped>
.banner_wrap {
  position: relative;
  width: 100%;
  height: 480px;
}
.banner_box {
  position: relative;
  z-index: 10;
  .swiper {
    .swiper_img {
      width: 100% !important;
      height: 480px !important;
      object-fit: cover;
    }
    .dot-title {
      width: 100%;
      height: 40px;
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // background-color: blueviolet;
      z-index: 999;
      .title-item {
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        background-color: black;
        opacity: 0.7;
        color: #fff;
        margin: 0 6px;
        cursor: pointer;
        border-radius: 4px 4px 0 0;
      }
      .active-title {
        background-color: #eb3d01;
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
}
.banner_container {
  position: absolute;
  z-index: 100;
  top: 0;
  width: 100%;
  .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    .img_box {
      display: flex;
      margin-left: 5px;
      &:last-child {
        margin-right: 5px;
      }
      img {
        cursor: pointer;
        width: 270px !important;
        height: 118px !important;
      }
    }
  }
}
</style>
